<template>
  <div class="client__wrap">
    <form class="client__form" @submit.prevent="addNewMeasurement">
      <div class="client__form-wrap">
        <div
          class="client__form-item"
          v-for="item in calcActualInfo()"
          :key="item.id"
        >
          <label :for="item.id" class="client__form-label">{{
            item.label
          }}</label>
          <base-input
            :id="item.id"
            v-if="item.elType === 'input'"
            :item="item"
          />
          <v-select
            v-else-if="item.elType === 'select'"
            :placeholder="item.placeholder"
            :options="item.options"
            v-model="item.model"
          />
        </div>
      </div>
      <error :error="error" />
      <base-btn>Зберегти</base-btn>
    </form>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      id: null,
      error: null,
      formInit: [
        {
          id: "growth",
          label: "Зріст",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "weight",
          label: "Вага",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: true,
        },
        {
          id: "glucose",
          label: "Глюкоза",
          elType: "input",
          type: "number",
          placeholder: "",
          model: "",
          visible: true,
        },
        {
          id: "lifestyle",
          label: "Спосіб життя",
          elType: "select",
          placeholder: "Пошук...",
          model: "",
          options: [
            { label: "Низький", id: "low" },
            { label: "Середній", id: "medium" },
            { label: "Активний", id: "high" },
            { label: "Лежачий", id: "recumbent" },
          ],
          visible: true,
        },

        {
          id: "smoke",
          label: "Куріння",
          elType: "select",
          placeholder: "Пошук...",
          model: "",
          options: [
            { label: "Сигарети", id: "cigarettes" },
            { label: "Кальян", id: "hookah" },
            { label: "Вейп тощо", id: "vape_other" },
            { label: "Не палю", id: "no_smoke" },
          ],
          visible: true,
        },
        {
          id: "count_cigarettes",
          label: "Кількість сигарет",
          elType: "input",
          placeholder: "",
          model: "",
          type: "number",
          visible: false,
        },
      ],
    };
  },
  watch: {
    formInit: {
      deep: true,
      handler(val) {
        if (val) {
          for (let key in val) {
            if (val[key].id === "smoke" && val[key].model.id === "cigarettes") {
              for (let i in this.formInit) {
                if (this.formInit[i].id === "count_cigarettes") {
                  this.formInit[i].visible = true;
                }
              }
            } else if (
              val[key].id === "smoke" &&
              val[key].model.id !== "cigarettes"
            ) {
              for (let i in this.formInit) {
                if (this.formInit[i].id === "count_cigarettes") {
                  this.formInit[i].visible = false;
                }
              }
            }
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
    }),
  },
  methods: {
    ...mapMutations(["setCurrentPatient"]),
    ...mapActions(["addMeasurement", "searchPatient"]),
    calcActualInfo() {
      let arr = [];
      this.formInit.map((el) => {
        if (el.visible) {
          arr.push(el);
        }
      });
      return arr;
    },
    addNewMeasurement() {
      let patientInfo = { patient_id: this.id };
      this.formInit.map((el) => {
        if (el.elType === "select") {
          if (el.model) {
            patientInfo[el.id] = el.model.id;
          } else {
            this.error = "Не всі поля заповнені";
            setTimeout(() => {
              this.error = null;
            }, 2000);
            return;
          }
        } else {
          if (el.model || (el.model && patientInfo.smoke === "cigarettes")) {
            patientInfo[el.id] = +el.model;
          } else if (patientInfo.smoke === "cigarettes" && !el.model) {
            this.error = "Не всі поля заповнені";
            setTimeout(() => {
              this.error = null;
            }, 2000);
            return;
          } else if (
            patientInfo.smoke !== "cigarettes" &&
            el.id === "count_cigarettes"
          ) {
            return;
          } else {
            this.error = "Не всі поля заповнені";
            setTimeout(() => {
              this.error = null;
            }, 2000);
            return;
          }
        }
      });

      for (let k in patientInfo) {
        if (k === "smoke" && patientInfo[k] !== "cigarettes") {
          delete patientInfo.count_cigarettes;
        }
      }
      if (!this.error) {
        this.addMeasurement(patientInfo)
          .then((resp) => {
            if (resp) {
              this.$router.push({ name: "CardPatient" });
            }
          })
          .catch((e) => {
            if (e.response.data.error_text === "token expired") {
              this.$router.replace({ name: "Login" });
            }
          });
      }
    },
  },
  mounted() {
    if (this.patient) {
      this.id = this.patient._id;
    } else {
      let obj = localStorage.getItem("patient");
      this.searchPatient(JSON.parse(obj)).then((resp) => {
        if (resp.data) {
          this.setCurrentPatient(resp.data);
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.client {
  &__form {
    max-width: 386px;
    margin: 0 auto;
    padding: 0 10px;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
    &-wrap {
      display: grid;
      grid-template: repeat(1fr) / repeat (1fr);
      gap: 16px 58px;
      margin-bottom: 20px;
    }
    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
}
</style>